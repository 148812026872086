@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
:root {
  --blue: #0954ff;
}
body {
  margin: 0;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.hs4U1k {
  background: none;
}
