:root {
  --blue: #0954ff;
}
h1{
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
}

li{
  list-style: none;
}

button {
  border:none;
  cursor: pointer;
}

button:focus {
  outline: orange 5px auto;
}

.container{
  max-width: 1170px;
  margin: 0 auto;
}

