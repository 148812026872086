.footer {
  background-color: #f9f9fa;
  padding: 50px 20px;
  margin-top: 50px;
}

.footer_contact,
.footer_contact_about {
  font-size: 10px;
}
.footer_contact_ul {
  padding: 0;
  margin: 0;
}

.footer_contact_menu {
  display: flex;
  flex-wrap: wrap;
}
.menu_item > a {
  margin-right: 10px;
  font-size: 10px;
  color: #3c763d;
  text-decoration: none;
}

.menu_item > a:hover {
  text-decoration: underline;
}
