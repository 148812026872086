.about_section {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
}

.about_section_logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.about_section_img {
  width: 120px;
  margin-right: 20px;
}
.about_section_icon {
  opacity: 3;
  color: #777;
  background-color: rebeccapurple;
}

/* Contetn */
.about_section_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.about_section_content_title {
  font-weight: 400;
  line-height: 1;
  color: #777;
  font-size: 9px;
}

.about_section_content_button {
  font-size: 9px;
  font-weight: 400;
  line-height: 1;
  color: #777;
  border: none;
  background: none;
}
