/* table{
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: transparent;
  font-size: 14px;
} */
.apr {
  padding: 20px;
  text-align: center;
}
.teble_resp {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
  min-height: .01%;
  overflow-x: auto;
}
#customers {
  width: 100%;

  border-collapse: collapse;
  font-size: 14px;
  overflow-y: hidden;
  overflow-x: auto;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-titles {
  background: var(--blue);
  color: #fff;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}


.apr_big_title:after {
  content: '';
  display: block;
  width: 150px;
  border-bottom: 3px solid var(--blue);
  margin: 25px auto 25px auto;
}