.factor {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 5% 0;
}
.factor_left {
  width: 50%;
  text-align: center;
}
.factor_left_img {
  width: 100%;

  max-width: 500px;
  border-radius: 20px;
  margin: 20px 0;
}
.factor_right {
  max-width: 500px;

  margin: 0 auto;
}
.factor_right_title_item {
  font-weight: 400;
}

.factor_right_title_item::after {
  content: "";
  display: block;
  width: 150px;
  border-bottom: 3px solid var(--blue);
  margin: 25px 0 15px 0;
}
.factor_right_title_green {
  color: var(--blue);
  font-weight: 400;
}

.factor_content_item {
  color: #696969;
  font-size: 14px;
  margin: 23px 0px;
}
.factor_content_item::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  margin-right: 5px;
}

.factor_button {
  color: #333;
  background-color: #fff;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: -0.5px;
  padding: 15px 30px;
  margin-top: 20px;
  transition: all;
  border: 1px solid var(--blue);
  transition: 0.2s;
}
.factor_button:hover {
  color: var(--blue);
}
@media (max-width: 1070px) {
  .factor_left {
    width: 100%;
  }
}
